<template>
  <v-hover v-slot="{ hover }">
    <div class="image-with-image-uploader">
      <v-img
        v-if="image && image.src"
        v-bind="image"
        :aspect-ratio="aspectRatio"
        :class="{ 'image-border': imageBorder }"
      ></v-img>
      <v-responsive v-else :aspect-ratio="aspectRatio">
        <add-item-card 
          v-if="adminMode"
          text="Legg til bilde"
          height="100%"
          @click="imageUploadDialog = true"
        ></add-item-card>
      </v-responsive>
      <v-btn
        v-if="image && image.src && adminMode"
        v-show="editBtnVisible || hover"
        outlined
        fab
        class="edit-btn"
        @click="imageUploadDialog = true"
        x-small
        color="primary"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <fixit-image-uploader
        v-model="imageUploadDialog"
        @response="onImageUploaded($event)"
        :aspect-ratio="aspectRatio"
        :image-object="imageObject"
        :uploadImageApiUrl="uploadImageApiUrl"
        :minWidth="minWidth"
        :minHeight="minHeight"
      />
    </div>
  </v-hover>
</template>

<script>
import FixitImageUploader from "@fixit/image-uploader";
import AddItemCard from "@/views/brands/components/AddItemCard.vue";
export default {
  name: "ImageWithImageUploader",
  components: {
    FixitImageUploader,
    AddItemCard,
  },
  props: {
     adminMode: {
      type: Boolean,
      required: false,
      default: false
    },
    image: {
      type: Object,
    },
    imageType: {
      required: true,
    },
    imageId: {
      required: true,
    },
    imageBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    aspectRatio: {
      type: Number,
    },
    editBtnVisible: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
      default: undefined,
    },
    minHeight: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      imageUploadDialog: false,
    };
  },
  computed: {
    imageObject() {
      return {
        imageType: this.imageType,
        imageId: this.imageId,
      };
    },
    uploadImageApiUrl() {
      const newImageUploadUrl = `${process.env.VUE_APP_API_URL}/api/images/upload`;
      // For exisiting item use the default upload URL.
      // For new items we don't want to the save the image yet. We only want the image data.
      return this.image == null || !this.imageId
        ? newImageUploadUrl
        : undefined;
    },
  },
  methods: {
    onImageUploaded(response) {
      this.$emit("image:uploaded", response);
    },
  },
};
</script>

<style scoped>
.image-with-image-uploader {
  position: relative;
}
.edit-btn {
  background: white;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
}

.image-border {
  --gradient-color-from: #bfe5e8;
  --gradient-color-to: #efddd1;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    var(--gradient-color-from) 0%,
    var(--gradient-color-to) 100%
  );
}
</style>