import { render, staticRenderFns } from "./ImageWithImageUploader.vue?vue&type=template&id=948d44e8&scoped=true&"
import script from "./ImageWithImageUploader.vue?vue&type=script&lang=js&"
export * from "./ImageWithImageUploader.vue?vue&type=script&lang=js&"
import style0 from "./ImageWithImageUploader.vue?vue&type=style&index=0&id=948d44e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "948d44e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VBtn,VHover,VIcon,VImg,VResponsive})
