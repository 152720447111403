<template>
  <v-breadcrumbs
    class="brand-serie-product-nav px-0 py-0"
    :items="items"
    v-bind="$attrs"
    large
    divider="/"
  ></v-breadcrumbs>
</template>

<script>
export default {
  name: "BrandSerieProductNav",
  props: {
    brand: {
      type: Object,
      required: false,
    },
    serie: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: false,
    },
  },
  computed: {
    items() {
      let items = [];
      items.push({
        text: "Merker",
        to: { name: "Brands" },
      });

      if (this.brand) {
        items.push({
          text: this.brand.name,
          to: { name: "Brand", params: { id: this.brand.id } },
        });
      }
      if (this.brand && this.serie) {
        items.push({
          text: this.serie.name,
          to: { name: "Serie", params: { id: this.serie.id } },
        });
      }
      if (this.brand && this.serie && this.product) {
        items.push({
          text: this.product.name,
          to: { name: "Product", params: { id: this.product.id } },
        });
      }

      return items;
    },
  },
};
</script>

<style scoped>
.brand-serie-product-nav >>> .v-breadcrumbs__item {
  font-weight: bold;
  font-size: 16px;
}
</style>