<template>
  <v-card
    class="add-item-card"
    v-bind="$attrs"
    tile
    flat
    @click="$emit('click')"
    :ripple="false"
  >
    <v-card-text
      class="d-flex flex-column align-center justify-center grey--text"
    >
      <slot name="icon">
        <v-icon v-bind="iconComputed">{{ iconComputed.name }}</v-icon>
      </slot>
      <span class="card-title" :style="{ color: colorComputed }">
        <slot>{{ text }}</slot>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
const defaultIcon = "mdi-plus";
const defaultIconSize = "48px";
const fixitGreen = "#bfe5e8";
const fixitPeach = "#efddd1";

export default {
  name: "AddItemCard",
  props: {
    color: {
      type: String,
      required: false,
    },
    gradientColors: { type: Array, required: false, default: () => [] },
    icon: {
      type: [String, Object],
      required: false,
      default: defaultIcon,
    },
    text: {
      type: String,
      required: false,
      default: "Legg til",
    },
  },
  computed: {
    colorComputed() {
      return this.color || this.$vuetify.theme.themes.light.primary;
    },
    iconComputed() {
      const defaultIconOptions = {
        name: defaultIcon,
        size: defaultIconSize,
        color: this.colorComputed,
      };
      if (typeof this.icon === "string") {
        return { ...defaultIconOptions, name: this.icon };
      }
      return {
        ...defaultIconOptions,
        ...this.icon,
      };
    },
    gradientColorsComputed() {
      return {
        top: this.gradientColors[0] || fixitGreen,
        bottom: this.gradientColors[1] || fixitPeach,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-item-card {
  --gradient-color-top: #bfe5e8;
  --gradient-color-bottom: #efddd1;
  display: flex;
  border: 2px dashed #fff;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(
        var(--gradient-color-top) 0%,
        var(--gradient-color-bottom) 100%
      )
      border-box;

  &.v-card--link:focus:before {
    opacity: 0;
  }

  .card-title {
    font-weight: bold;
    font-size: 16px;
  }
}
</style>